<template>
  <div class="width100 margin-bottom2">
    <div class="flex flex-center">
      <span class="flex1 fontSize16 font-weight letter-spacing1">{{ name }}</span>
      <div @click="showMore = !showMore" class="cursor-pointer actives05 fontSize14">
        <span>查看更多</span>
        <i v-if="!showMore" class="el-icon-caret-bottom fontSize18 margin-left1"></i>
        <i v-else class="el-icon-caret-top fontSize18 margin-left1"></i>
      </div>
    </div>
    <div class="fontSize14 clear-both-after overflow-hidden" :style="!showMore ? 'height: 28px' : ''">
      <span v-for="(item,index) in sourceData" :key="index" @click="toggleSelect(item)"
            class="float-left margin-right3 line-height2 cursor-pointer actives05" :class="indexInArr(item,checked) > -1 ? 'textColor19' : ''">
        {{ item }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    sourceData: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showMore: false,
      checked: []
    };
  },
  watch: {
    checked: {
      handler: function (newVal) {
        this.$emit('queryChange', newVal)
      }
    },
    selected: {
      handler: function (newVal) {
        if (newVal.toString() !== this.checked.toString()) {
          this.checked = [...newVal]
        }
      }
    },
  },
  methods: {
    reset() {
      this.checked = []
    },
    toggleSelect(item) {
      const index = this.indexInArr(item, this.checked)
      if (index > -1) {
        this.checked.splice(index, 1)
      } else {
        this.checked.push(item)
      }
    },
    indexInArr(item, arr) {
      const index = arr.findIndex((arrItem) => {
        return arrItem === item
      })
      return index
    }
  },
};
</script>
