import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

//获取自己公司详情
export function getCompany() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies",
    method: "get"
  });
}

//创建账号
export function addcompany(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies",
    method: "post",
    data
  });
}

//更新公司详情
export function updateCompany(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/update",
    method: "post",
    data
  });
}
//获取公司员工邀请码
export function getCompanyCode() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/staff-invite-code",
    method: "get"
  });
}

//员工申请加入公司
export function joinCompany(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs/apply",
    method: "post",
    data
  });
}

//根据公司员工邀请码获取公司信息
export function getCompanyByCode(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/by-staff-invite-code",
    method: "get",
    params
  });
}

//获取指定公司详情
export function getCompanyById(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/" + params.id,
    method: "get"
  });
}

//获取公司列表
export function postCompanyList(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/list",
    method: "post",
    data
  });
}

//公司列表过滤项
export function postCompanyListFilter(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/list/filter",
    method: "post",
    data
  });
}