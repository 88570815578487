<template>
  <div class="width100">
    <div class="content-box margin-auto margin-top3 margin-bottom3 search-form">
      <!-- search -->
      <SearchForm @query="query"/>
    </div>
    <div class="content-box margin-auto margin-top6 margin-bottom3">
      <!-- 基础信息 -->
      <BaseInfo :info="info" @update="initStoreInfo"/>
    </div>
    <div class="content-box margin-auto margin-top3 margin-bottom3">
      <!-- tab -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部商品" name="1">
          <GoodsData :id="info.id" :keyword="keyword" :isNew="false"/>
        </el-tab-pane>
        <el-tab-pane label="最近商品" name="2">
          <GoodsData :id="info.id" :keyword="keyword" :isNew="true"/>
        </el-tab-pane>
        <el-tab-pane label="商家资料" name="3">
          <StoreInfo :info="info"/>
        </el-tab-pane>
        <el-tab-pane label="联系方式" name="4">
          <ContactInfo :info="info"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <fixedNav/>
  </div>
</template>
<script>
import SearchForm from './components/searchForm'
import fixedNav from "@/components/fixedNav/fixedNav";
import BaseInfo from "./components/baseInfo";
import GoodsData from "./components/goodsData";
import StoreInfo from "./components/storeInfo";
import ContactInfo from "./components/contactInfo";
import {getCompanyById} from "@/api/home/company";

export default {
  components: {
    SearchForm,
    BaseInfo,
    GoodsData,
    StoreInfo,
    ContactInfo,
    fixedNav
  },
  data() {
    return {
      activeName: "1",
      keyword: "",
      info: {},
    };
  },
  created() {
    this.initStoreInfo();
  },
  methods: {
    initStoreInfo() {
      getCompanyById({
        id: this.$route.query.storeId,
      }).then((res) => {
        this.info = res;
      });
    },
    query(keyword) {
      this.keyword = keyword
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style scoped>
/deep/ .el-tabs__item.is-active,
/deep/ .el-tabs__item:hover {
  color: #FF6E4C !important;
}

/deep/ .el-tabs__active-bar {
  background-color: #FF6E4C !important;
}
</style>
