<template>
  <div class="width100">
    <queryBlock name="配件品牌" ref="brand" :sourceData="brandMap" @queryChange="brandChange"/>
    <queryBlock name="品质" ref="quality" :sourceData="propertyMap" @queryChange="qualityChange"/>
    <div class="width100 margin-bottom2">
      <div class="flex flex-center">
        <span class="flex1 fontSize16 font-weight letter-spacing1">其他</span>
      </div>
      <div class="margin-top1 fontSize14 flex flex-center">
        <el-checkbox v-model="query1Extra.hasStock">
          <span class="textColor03">有货</span>
        </el-checkbox>
        <div class="flex1"></div>
      </div>
    </div>

    <div class="width100 margin-top4">
      <div class="flex flex-center fontSize14 background-color07 textColor10 border-radius1 padding2 box-sizing">
        <span>为您找到以下商品：</span>
        <div class="flex1 margin-left4 margin-right4 fontSize12">
          <el-tag v-if="checkedBrand.length" size="mini" closable @close="clearBrand" class="margin-right2">
            {{ checkedBrand.join("、") }}
          </el-tag>
          <el-tag v-if="checkedQuality.length" size="mini" closable @close="clearQuality" class="margin-right2">
            {{ checkedQuality.join("、") }}
          </el-tag>
          <el-tag v-if="query1Extra.hasStock" size="mini" closable @close="query1Extra.hasStock = false" class="margin-right2">
            有货
          </el-tag>
        </div>
        <span @click="emptyCondition" class="cursor-pointer actives05">清空条件</span>
        <!--<span class="margin-left2 fontSize12">
          共{{ totalSize }}商品，共{{ totalSize ? totalPage : 0 }}页，{{ totalSize ? page : 0 }}页
        </span>-->
      </div>
    </div>
  </div>
</template>
<script>
import queryBlock from "./queryBlock.vue";
import {getGoodsFilterApi} from "@/api/home/goods";

export default {
  components: {
    queryBlock,
  },
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0
    },
    keyword: {
      type: String,
      default: "",
    },
    page: {
      type: Number,
      default: 0
    },
    totalPage: {
      type: Number,
      default: 0
    },
    totalSize: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      brandMap: [],
      brandsMap: [],
      vehBrandsMap: [],
      propertyMap: [],
      categoriesMap: [],
      autoPartsCityMap: [],
      query1Extra: {
        thisCity: false,
        hasStock: true,
        followStore: false,
      },
      query2Extra: {
        thisCity: false,
        onlyPartsOn: false,
        followStore: false,
      },
      checkedBrand: [],
      checkedQuality: [],
      checkedBrands: [],
      checkedVehBrandsMap: [],
      checkedCategories: [],
      checkedAutoPartsCityMap: []
    };
  },
  watch: {
    keyword: {
      handler: function (newVal) {
        if (newVal) {
          this.makeGoodsFilter();
        }
      },
    },
    id: {
      handler: function (newVal) {
        if (newVal) {
          this.makeGoodsFilter();
        }
      },
    },
    query1Extra: {
      handler: function () {
        this.makeGoodsQueryForm();
      },
      deep: true
    },
  },
  mounted() {
    this.makeGoodsFilter()
  },
  methods: {
    clearBrand() {
      this.checkedBrand = []
      this.$refs.brand.reset()
    },
    clearQuality() {
      this.checkedQuality = []
      this.$refs.quality.reset()
    },
    brandChange(e) {
      this.checkedBrand = e;
      this.makeGoodsQueryForm();
    },
    qualityChange(e) {
      this.checkedQuality = e;
      this.makeGoodsQueryForm();
    },
    makeGoodsQueryForm() {
      this.$emit("queryChange", {
        ...this.query1Extra,
        brand: this.checkedBrand.join(','),
        quality: this.checkedQuality.join(','),
      });
    },
    makeGoodsFilter() {
      if (!this.id) return
      const params = {
        companyId: this.id,
        new: this.isNew,
        keyword: this.keyword,
      }
      if (!this.keyword) {
        delete params.keyword
      }
      getGoodsFilterApi(params).then((res) => {
        if (!res) {
          this.brandMap = [];
          this.propertyMap = [];
        }
        const {brand, quality} = res;
        this.brandMap = brand || [];
        this.propertyMap = quality || [];
      });
    },
    emptyCondition() {
      this.checkedBrand = [];
      this.checkedQuality = [];
      this.query1Extra.hasStock = false;

      this.$refs.brand.reset();
      this.$refs.quality.reset();
    }
  },
};
</script>
