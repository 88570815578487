<template>
    <div
        ref="qrcode"
        :style="'width:'+ this.width +'px;height:'+ this.height +'px'"
        class="code-canvas"
    />
</template>

<script>
import QRCode from "qrcodejs2";

export default {
    name: "Qrcodes",
    props: {
        value: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            default: 60,
            required: true
        },
        height: {
            type: Number,
            default: 60,
            required: true
        }
    },
    data() {
        return {
            qrcode: null
        };
    },
    watch: {
        value(val) {
            if (val) {
                this.makeQrcode();
            }
        }
    },
    mounted() {
        this.makeQrcode();
    },
    methods: {
        makeQrcode() {
            if (this.qrcode) {
                this.qrcode.clear();
                this.qrcode.makeCode(this.value);
            } else {
                this.qrcode = new QRCode(this.$refs.qrcode, {
                    width: this.width,
                    height: this.height,
                    text: this.value,
                    correctLevel: QRCode.CorrectLevel.L
                });
            }
        }
    }
};
</script>