<template>
  <div class="width100 textColor06 fontSize14 store-info margin-bottom6 box-sizing padding3 contact-info">
    <div class="width100">
      <span class="fontSize16 font-weight">联系方式</span>
      <span class="fontSize12 textColor09 margin-left2">有任何问题欢迎电话联系或添加微信QQ</span>
    </div>
    <div class="flex margin-top2">
      <div>
        <p class="fontSize15">联系电话</p>
        <p>
          <span>主联系人1：{{ info.contacts }}</span>
          <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone)">{{ info.phone }}</a>
        </p>
        <p v-if="info.contacts1">
          <span>联系人1：{{ info.contacts1 }}</span>
          <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone1)">{{ info.phone1 }}</a>
        </p>
        <p v-if="info.contacts2">
          <span>联系人2：{{ info.contacts2 }}</span>
          <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone2)">{{ info.phone2 }}</a>
        </p>
        <p v-if="info.contacts3">
          <span>联系人3：{{ info.contacts3 }}</span>
          <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone3)">{{ info.phone3 }}</a>
        </p>
      </div>
      <div class="margin-left6 padding-left6">
        <p class="fontSize15">IM联系</p>
        <div v-for="(item, index) in contactList" :key="index" class="flex align-items-center margin-bottom1">
          <span class="w-16 h-16 border-radius100" :class="item.isOnline ? 'background-color27' : 'background-color32'"></span>
          <span class="margin-left2" style="width: 100px">{{ item.name }}</span>
          <p @click="sendMsg(item)" class="flex flex-center cursor-pointer actives05">
            <span class="textColor23">立即联系</span>
            <img src="@/assets/home/message.png" class="w-24 h-24 margin-left2" alt="">
          </p>
        </div>
      </div>
    </div>
    <div class="margin-top2">
      <p class="fontSize15">
        <span>微信</span>
        <span class="fontSize12 textColor09 margin-left2">点击二维码添加微信</span>
      </p>
      <p>微信号：{{ info.wechat }}</p>
      <div v-if="info.wechatImages && info.wechatImages.length" class="flex">
        <el-image
          class="margin-right2 QR-code-img"
          v-for="(item, index) in info.wechatImages"
          :src="item"
          :key="index"
          :preview-src-list="info.wechatImages"
          fit="cover"
          alt="点击二维码添加微信"
        />
      </div>
    </div>
    <div class="margin-top2">
      <p class="fontSize15">
        <span>QQ</span>
        <span class="fontSize12 textColor09 margin-left2">点击二维码添加QQ</span>
      </p>
      <p>QQ号：{{ info.qq }}</p>
      <div v-if="info.qqImages && info.qqImages.length" class="flex">
        <el-image
          class="margin-right2 QR-code-img"
          v-for="(item, index) in info.qqImages"
          :src="item"
          :key="index"
          :preview-src-list="info.qqImages"
          fit="cover"
          alt="点击二维码添加QQ"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {getContactStaffs} from '@/api/home/chat'

export default {
  props: {
    info: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      contactList: []
    };
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.id) {
          this.getContactStaffs();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.info.id) {
      this.getContactStaffs();
    }
  },
  methods: {
    getContactStaffs() {
      if (!this.info.id) return;
      let params = {
        companyId: this.info.id
      };
      getContactStaffs(params).then(res => {
        this.contactList = res || [];
      });
    },
    callPhoneCount(phone) {
      window.location.href = "tel:" + phone;
    },
    sendMsg(e) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
  },
};
</script>

<style lang="less" scoped>
.contact-info p {
  line-height: 2em;
}

.contact-info .QR-code-img {
  width: 120px;
  height: 120px;
}
</style>
