<template>
  <div class="width100 textColor06 fontSize14 store-info margin-bottom6">
    <div class="margin-top2 flex align-items-center">
      <div class="store-info-left">
        公司名称
      </div>
      <div class="store-info-right">
        {{ info.name }}
      </div>
    </div>
    <div class="margin-top2 flex align-items-center">
      <div class="store-info-left">
        公司地址
      </div>
      <div class="store-info-right">
        <span>{{ info.areas ? info.areas.join("/") : "" }}</span>
        <span class="margin-left2">{{ info.address }}</span>
        <span class="margin-left2">{{ info.autoPartsCity }}</span>
      </div>
    </div>
    <div class="margin-top2 flex align-items-center">
      <div class="store-info-left">
        公司电话
      </div>
      <div class="store-info-right">
        <a v-if="info.phone" @click.stop="callPhoneCount(info.phone)" class="textColor23 cursor-pointer">
          {{ info.phone }}
        </a>
        <span v-else>暂无数据</span>
      </div>
    </div>
    <div class="margin-top2 flex">
      <div class="store-info-left">
        公司介绍
      </div>
      <div class="store-info-right">
        <span>{{ info.description || "暂无数据" }}</span>
      </div>
    </div>
    <div class="margin-top2 flex">
      <div class="store-info-left">
        主营配件分类
      </div>
      <div class="store-info-right">
        <div v-if="info.categories && info.categories.length" class="overflow-hidden clear-both-after">
          <span v-for="(item, index) in info.categories" :key="index" class="float-left background-color31 border-radius1 fontSize12 padding1 padding-left2 padding-right2 margin-right2 margin-bottom2">
            {{ renderCategory(item) }}
          </span>
        </div>
        <span v-else>暂无数据</span>
      </div>
    </div>
    <div class="margin-top2 flex">
      <div class="store-info-left">
        经营车型品牌
      </div>
      <div class="store-info-right">
        <div v-if="info.vehBrands && info.vehBrands.length" class="overflow-hidden clear-both-after">
          <span v-for="(item, index) in info.vehBrands" :key="index" class="float-left background-color31 border-radius1 fontSize12 padding1 padding-left2 padding-right2 margin-right2 margin-bottom2">
            {{ item }}
          </span>
        </div>
        <span v-else>暂无数据</span>
      </div>
    </div>
    <div class="margin-top2 flex">
      <div class="store-info-left">
        经营配件品牌
      </div>
      <div class="store-info-right">
        <div v-if="info.brands && info.brands.length" class="overflow-hidden clear-both-after">
          <span v-for="(item, index) in info.brands" :key="index" class="float-left background-color31 border-radius1 fontSize12 padding1 padding-left2 padding-right2 margin-right2 margin-bottom2">
            {{ item }}
          </span>
        </div>
        <span v-else>暂无数据</span>
      </div>
    </div>
    <div class="margin-top2 flex align-items-center">
      <div class="store-info-left">
        公司网站
      </div>
      <div class="store-info-right">
        <a v-if="info.website" :href="info.website" target="_blank" class="textColor23">
          {{ info.website }}
        </a>
        <span v-else>暂无数据</span>
      </div>
    </div>
    <div class="margin-top2 flex">
      <div class="store-info-left">
        公司公众号
      </div>
      <div class="store-info-right flex1 flex flex-direction-column">
        <div v-if="info.wechatOffiCccountImage">
          <p class="fontSize12 textColor09">点击二维码关注公众号</p>
          <el-image
            class="cursor-pointer QR-code-img1"
            :src="info.wechatOffiCccountImage"
            :preview-src-list="[info.wechatOffiCccountImage]"
            alt="点击二维码关注公众号"
            fit="cover"
          />
        </div>
        <span v-else>暂无数据</span>
      </div>
    </div>
    <div class="margin-top2 flex">
      <div class="store-info-left">
        联系方式
      </div>
      <div class="store-info-right flex1 flex">
        <div class="flex1 contact-info">
          <p class="font-weight">联系电话</p>
          <p class="fontSize12 textColor09">有任何问题欢迎电话联系或添加微信QQ</p>
          <p>
            <span>主联系人1：{{ info.contacts }}</span>
            <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone)">{{ info.phone }}</a>
          </p>
          <p v-if="info.contacts1">
            <span>联系人1：{{ info.contacts1 }}</span>
            <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone1)">{{ info.phone1 }}</a>
          </p>
          <p v-if="info.contacts2">
            <span>联系人2：{{ info.contacts2 }}</span>
            <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone2)">{{ info.phone2 }}</a>
          </p>
          <p v-if="info.contacts3">
            <span>联系人3：{{ info.contacts3 }}</span>
            <a class="margin-left2 cursor-pointer" @click.stop="callPhoneCount(info.phone3)">{{ info.phone3 }}</a>
          </p>
        </div>
        <div class="flex1 contact-info">
          <p>
            <span class="font-weight">微信</span>
            <span class="fontSize12 textColor09 margin-left2">点击二维码添加微信</span>
          </p>
          <p>微信号：{{ info.wechat }}</p>
          <div v-if="info.wechatImages && info.wechatImages.length" class="overflow-hidden clear-both-after">
            <el-image
              class="QR-code-img2 float-left box-sizing padding-right1 cursor-pointer"
              v-for="(item, index) in info.wechatImages"
              :src="item"
              :key="index"
              :preview-src-list="info.wechatImages"
              fit="cover"
              alt="点击二维码添加微信"
            />
          </div>
        </div>
        <div class="flex1 contact-info">
          <p>
            <span class="font-weight">QQ</span>
            <span class="fontSize12 textColor09 margin-left2">点击二维码添加QQ</span>
          </p>
          <p>QQ号：{{ info.qq }}</p>
          <div v-if="info.qqImages && info.qqImages.length" class="overflow-hidden clear-both-after">
            <el-image
              class="QR-code-img2 float-left box-sizing padding-right1 cursor-pointer"
              v-for="(item, index) in info.qqImages"
              :src="item"
              :key="index"
              :preview-src-list="info.qqImages"
              fit="cover"
              alt="点击二维码添加QQ"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    renderCategory(item) {
      return `${item[0]} > ${item[1]}`;
    },
    callPhoneCount(phone) {
      window.location.href = "tel:" + phone;
    },
  },
};
</script>

<style lang="less" scoped>
.store-info .store-info-left {
  width: 100px;
  min-width: 100px;
  text-align: right;
  font-size: 14px;
  font-weight: bolder;
}

.store-info .store-info-right {
  margin-left: 30px;
}

.store-info .store-info-right .contact-info p {
  line-height: 2em;
}

.store-info .store-info-right .contact-info-img {
  width: 20%;
}

.store-info .store-info-right .QR-code-img1 {
  width: 120px;
  height: 120px;
}

.store-info .store-info-right .QR-code-img2 {
  width: 80px;
  height: 80px;
}
</style>
