<template>
  <div class="width100 base-info">
    <div class="flex">
      <div class="flex1 relative">
          <el-carousel height="200px" :autoplay="true">
          <template v-if="info.images && info.images.length">
            <el-carousel-item v-for="(item,index) in info.images" :key="item">
              <!-- <el-image class="banner-img width100" :src="item" :previewSrcList="info.images" fit="cover"></el-image> -->
              <el-image class="banner-img width100" :src="item" @click="imgClick(index,info.images)" fit="cover"></el-image>
            </el-carousel-item>
          </template>
          <template v-else>
            <el-carousel-item>
              <!-- <el-image class="banner-img width100" :src="defaultImg" :previewSrcList="[defaultImg]" fit="cover"></el-image> -->
              <el-image class="banner-img width100" :src="defaultImg" @click="imgClick(0,defaultImg)" fit="cover"></el-image>
            </el-carousel-item>
          </template>
        </el-carousel>
        <div v-if="dynamicList.length" class="position-absolute top0 left0 width100 box-sizing padding2 textColor10 background-opacity2 z-index100">
          <el-carousel height="20px" indicator-position="none" direction="vertical" :autoplay="true">
            <el-carousel-item v-for="(item, index) in dynamicList" :key="index">
              <span>{{ item.content }}</span>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="flex2 margin-left4 flex flex-direction-column">
        <div class="fontSize13 font-weight400 flex align-items-center">
          <span class="fontSize16 textColor03 font-weight">{{ info.name }}</span>
          <span v-if="info.type" class="padding-left1 padding-right1 border-radius1 textColor24 border4 background-color29 margin-right2 margin-left4">
            {{ renderType() }}
          </span>
          <span v-if="info.flagThreesoft" class="padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
            思锐认证
          </span>
          <span v-if="info.flagOptimization" class="padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
            优质商家
          </span>
          <span v-if="info.flagPower" class="padding-left1 padding-right1 border-radius1 textColor23 border3">
            实力商家
          </span>
        </div>
        <div class="fontSize14 textColor06 font-weight400 margin-top1">
          <span>{{ info.areas ? info.areas[1] : "" }}</span>
          <span class="margin-left2">{{ info.autoPartsCity }}</span>
        </div>
        <div v-if="info.description" class="flex1 fontSize12 textColor09 font-weight400 margin-top1 line-height2 text-show-five">
          <span v-if="info.flagOptimization" class="fontSize14 textColor10 background-color27 padding-left1 padding-right1 margin-right2" style="padding-top: 2px;padding-bottom: 2px">荐</span>
          <span v-text="info.description"></span>
        </div>
        <div v-if="info.counts" class="fontSize12 textColor09 font-weight400 margin-top1">
          <span class="margin-right2">{{ info.counts.follows || "0" }}人关注</span>
          <span class="margin-right2">{{ info.counts.browses || "0" }}人浏览</span>
          <span class="margin-right2">{{ info.counts.consultations || "0" }}人咨询</span>
          <span class="margin-right2">{{ info.counts.parts || "0" }}件商品</span>
        </div>
      </div>
      <div class="margin-left6 flex flex-direction-column textColor03 fontSize14">
        <div v-show="$store.state.profile.id">
          <div v-if="info.followed" @click.stop="delCollect(info)" class="flex align-items-center cursor-pointer actives05">
            <svg t="1667186806096" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1247" width="16" height="16">
              <path
                d="M892.543016 224.150106c-9.284457-11.914354-17.804505-21.814842-26.454512-30.930453C759.437485 80.827887 642.682341 92.003414 536.033369 176.799682c-9.493212 7.547907-18.453281 15.383362-26.88737 23.346731-8.43409-7.963369-17.395182-15.798824-26.888394-23.346731C375.608633 92.003414 258.853489 80.827887 152.202471 193.21863c-8.650007 9.115612-17.170055 19.016099-25.559119 29.714765C-2.680039 410.134984 68.411089 595.897805 259.728416 764.030084c42.320874 37.192064 87.560218 70.64906 132.799562 99.905384 15.841803 10.245342 30.570249 19.244296 43.816948 26.932396 8.024767 4.657067 13.827937 7.872295 17.044188 9.578146 4.869914 2.916423 9.728572 5.142114 14.530948 6.771217 3.470031 1.619894 7.516184 3.091408 12.218276 4.387937 25.377994 6.998391 62.97938 1.908466 85.839017-11.764951 2.14178-1.101077 7.944949-4.315282 15.969717-8.972349 13.246699-7.688099 27.974122-16.687054 43.816948-26.932396 45.239344-29.256324 90.478687-62.71332 132.799562-99.905384C949.879885 595.897805 1020.971014 410.134984 892.543016 224.150106z"
                fill="#d81e06" p-id="1248"></path>
            </svg>
            <span class="margin-left2">取消关注</span>
          </div>
          <div v-else @click.stop="collect(info)" class="flex align-items-center cursor-pointer actives05">
            <svg t="1667186659379" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1093" width="16" height="16">
              <path
                d="M908.8 214.4c-9.6-12.8-19.2-22.4-28.8-32-112-115.2-230.4-105.6-342.4-16-9.6 6.4-19.2 16-28.8 25.6-9.6-9.6-19.2-16-28.8-25.6-112-86.4-230.4-99.2-342.4 16-9.6 9.6-19.2 19.2-25.6 32-134.4 195.2-60.8 387.2 137.6 560 44.8 38.4 89.6 73.6 137.6 102.4 16 9.6 32 19.2 44.8 28.8 9.6 6.4 12.8 9.6 19.2 9.6 3.2 3.2 6.4 3.2 12.8 6.4 3.2 3.2 9.6 3.2 16 6.4 25.6 6.4 64 3.2 89.6-12.8 3.2 0 9.6-3.2 16-9.6 12.8-6.4 28.8-16 44.8-28.8 48-28.8 92.8-64 137.6-102.4C969.6 598.4 1043.2 406.4 908.8 214.4zM736 732.8c-41.6 35.2-86.4 70.4-131.2 99.2-16 9.6-28.8 19.2-44.8 25.6-6.4 3.2-12.8 6.4-16 9.6-6.4 3.2-16 6.4-25.6 9.6-3.2 0-6.4 0-9.6 0-6.4 0-12.8 0-16 0-3.2 0-3.2 0-3.2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-3.2 0-3.2-3.2-3.2 0-6.4-3.2-9.6-3.2-3.2-3.2-9.6-6.4-16-9.6-12.8-6.4-28.8-16-44.8-25.6-44.8-28.8-89.6-60.8-131.2-99.2-179.2-160-243.2-323.2-131.2-489.6 6.4-9.6 16-16 22.4-25.6 89.6-96 182.4-86.4 275.2-12.8 9.6 6.4 16 12.8 22.4 19.2 0 0 0 0 0 0l28.8 32c3.2 3.2 3.2 3.2 6.4 6.4 0 0 0 0 0 0l0 0c3.2-3.2 9.6-9.6 16-16 12.8-12.8 25.6-25.6 41.6-38.4 92.8-73.6 185.6-83.2 275.2 12.8 6.4 9.6 16 16 22.4 25.6C982.4 406.4 918.4 572.8 736 732.8z"
                fill="#8a8a8a" p-id="1094"></path>
            </svg>
            <span class="margin-left2">关注</span>
          </div>
        </div>
        <div class="margin-top1">
          <el-popover placement="left" trigger="hover" class="text-align-center cursor-pointer">
            <div slot="reference" class="flex align-items-center fontSize14 textColor03">
              <div>
                <svg t="1667613630931" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="969" width="16" height="16">
                  <path
                    d="M597.333333 597.333333h85.333334v-85.333333h85.333333v128h-85.333333v42.666667h-85.333334v-42.666667h-85.333333v-128h85.333333v85.333333z m-384-85.333333h256v256H213.333333v-256z m85.333334 85.333333v85.333334h85.333333v-85.333334H298.666667zM213.333333 213.333333h256v256H213.333333V213.333333z m85.333334 85.333334v85.333333h85.333333V298.666667H298.666667z m213.333333-85.333334h256v256h-256V213.333333z m85.333333 85.333334v85.333333h85.333334V298.666667h-85.333334z m85.333334 384h85.333333v85.333333h-85.333333v-85.333333z m-170.666667 0h85.333333v85.333333h-85.333333v-85.333333z"
                    fill="#EDD260" p-id="970"></path>
                </svg>
              </div>
              <div class="margin-left2">手机逛</div>
            </div>
            <div>
              <Code :value="storeUrl" :width='100' :height='100'/>
            </div>
          </el-popover>
        </div>
        <div @click.stop="copyLink(info)" class="margin-top1 flex flex-center cursor-pointer actives05">
          <svg t="1667186930963" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1401" width="16" height="16">
            <path
              d="M558.933333 699.733333l-170.666666-93.866666c-25.6 21.333333-55.466667 34.133333-89.6 34.133333-72.533333 0-128-55.466667-128-128s55.466667-128 128-128c38.4 0 68.266667 17.066667 93.866666 38.4L597.333333 320V298.666667c0-72.533333 55.466667-128 128-128s128 55.466667 128 128-55.466667 128-128 128c-34.133333 0-64-12.8-85.333333-34.133334l-213.333333 110.933334v21.333333l174.933333 98.133333c21.333333-17.066667 51.2-29.866667 81.066667-29.866666 72.533333 0 128 55.466667 128 128s-55.466667 128-128 128-128-55.466667-128-128c0-4.266667 0-12.8 4.266666-21.333334zM298.666667 554.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667-42.666667 17.066667-42.666667 42.666667 17.066667 42.666667 42.666667 42.666667z m426.666666-213.333334c25.6 0 42.666667-17.066667 42.666667-42.666666s-17.066667-42.666667-42.666667-42.666667-42.666667 17.066667-42.666666 42.666667 17.066667 42.666667 42.666666 42.666666z m-42.666666 426.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666666-42.666667 17.066667-42.666667 42.666666 17.066667 42.666667 42.666667 42.666667z"
              fill="#F3D146" p-id="1402"></path>
          </svg>
          <span class="margin-left2">复制链接</span>
        </div>
        <div @click="sendMsg(info.id)" class="margin-top1 flex flex-center cursor-pointer actives05">
          <svg t="1667186969453" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1555" width="16" height="16">
            <path
              d="M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z"
              fill="#F3D146" p-id="1556"></path>
          </svg>
          <span class="margin-left2">咨询商家</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {dynamicListApi} from "@/api/home/log";
import {addFocusStoreApi, delFocusStoreApi} from "@/api/focus";
import {getContactStaffs} from "@/api/home/chat";
import Code from './code.vue'
import eventBus from "@/event";

export default {
  props: {
    info: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    Code
  },
  data() {
    return {
      typeClass: "flag_j",
      dynamicList: [],
      defaultImg: require("@/assets/maintenance/default-store.png"),
    };
  },
  watch: {
    info: {
      handler: function (val) {
        this.init();
      },
      deep: true,
    },
  },
  computed: {
    storeUrl() {
      return location.origin + '/h5/storeDetail?companyId=' + this.info.id
    }
  },
  methods: {
    imgClick(index,images){
          eventBus.$emit("ShowImageViewer", {
          index: index,
          list: images
        });
    },
    init() {
      dynamicListApi({objectType: "store", objectId: this.info.id}).then(
        (res) => {
          this.dynamicList = [...res];
        }
      );
    },
    renderType() {
      let str = "";
      switch (this.info.type) {
        case "dealer":
          str = "经销商";
          this.typeClass = "flag_j";
          break;
        case "garage":
          str = "汽修厂";
          this.typeClass = "flag_q";
          break;
        case "manufacturer":
          str = "生产厂家";
          this.typeClass = "flag_s";
          break;
      }
      return str;
    },
    collect(e) {
      if (!this.$store.state.profile.id) {
        this.$router.push('/login')
        return
      }
      addFocusStoreApi({
        id: e.id,
      }).then((res) => {
        this.$message.success("关注成功");
        this.$emit("update");
      });
    },
    delCollect(e) {
      delFocusStoreApi({
        id: e.id,
      }).then((res) => {
        this.$emit("update");
      });
    },
    sendMsg() {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      getContactStaffs({
        companyId: this.info.id,
      }).then((res) => {
        this.$store.dispatch("setChatId", res[0].userId);
        this.$store.dispatch("changeIMVisible", true);
      });
    },
    copyLink(row) {
      const link = `${location.origin}/home/store?storeId=${row.id}&tabsActive=storeInfo`;
      const _this = this;
      this.$copyText(link).then(
        function (e) {
          _this.$message({
            message: "已复制到剪贴板",
            type: "success",
          });
        },
        function (e) {
          _this.$message({
            message: "复制失败",
            type: "error",
          });
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.base-info .banner-img {
  height: 200px;
}

/deep/ .el-carousel .is-active .el-carousel__button {
  background-color: #FF6E4C !important;
}
</style>
