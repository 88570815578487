<template>
  <div class="flex flex-center search-form">
    <div>
      <img class="cursor-pointer logo-img" src="@/assets/home/logo@3x.png" alt="logo" @click="$router.push('/home/index')"/>
    </div>
    <div class="flex1 margin-left6 padding-left6">
      <div class="flex flex-center search-box border-radius6 overflow-hidden background-color27">
        <input @keydown.enter="query" type="text" v-model="keyword" placeholder="输入配件名称、OE号、品牌、车型等关键字查询"
               class="search-box-input flex1 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize14"/>
        <div @click="query" class="flex flex-center background-color27 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize16 textColor10 cursor-pointer actives05">
          <span>搜本店</span>
          <img class="margin-left1 search-img" src="@/assets/home/search.png" alt="search">
        </div>
      </div>
    </div>
    <div @click="gotoSearch" class="margin-left4 flex flex-center fontSize16 textColor10 flex flex-center background-color27 cursor-pointer actives05 line-height1 padding3 padding-left4 padding-right4 border-radius6">
      <span>搜全站</span>
      <img class="margin-left1 search-img" src="@/assets/home/search.png" alt="search">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },
  mounted() {
    const keyword = this.$route.query.query
    if (keyword) {
      this.keyword = keyword
      this.query()
    }
  },
  methods: {
    query() {
      this.$emit("query", this.keyword);
    },
    gotoSearch() {
      this.$router.push(`/home/search?keyword=${this.keyword}`);
    },
  },
};
</script>
<style scoped>
.search-form .logo-img {
  height: 64px;
}

.search-form .search-img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.search-form .search-box {
  border: 3px solid #FF6E4C;
  padding-left: 2px;
}

.search-form .search-box-input {
  border: none;
  outline: none;
  border-radius: 20px 0 0 20px;
}
</style>